import { Component } from '@angular/core';

@Component({
  selector: 'ui-banner',
  templateUrl: './banner.component.html',
  styles: []
})
export class BannerComponent {
  constructor() {}
}

<header>
  <div class="p-grid">
    <div class="p-col-3">
      <div class="logo">
        <img src="assets/images/logo.png" alt="logo" />
      </div>
    </div>
    <div class="p-col-4 search"><products-search></products-search></div>
    <div class="p-col-3 p-offset-1 navigation">
      <ngshop-nav></ngshop-nav>
    </div>
    <div class="p-col-1 tools">
      <i class="pi pi-user p-text-secondary" style="font-size: 1.5rem"></i>
      <orders-cart-icon></orders-cart-icon>
    </div>
  </div>
</header>

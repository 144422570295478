<footer>
  <div class="p-grid footer-wrapper">
    <div class="p-col-8">&copy; 1994 - 2022 E-Shop</div>
    <div class="p-col-4 social">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="#"><i class="pi pi-facebook"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#"><i class="pi pi-twitter"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#"><i class="pi pi-youtube"></i></a>
        </li>
      </ul>
    </div>
  </div>
</footer>

<div class="product-page" *ngIf="product">
  <div class="p-grid">
    <div class="p-col-6">
      <ui-gallery [images]="product.images"></ui-gallery>
    </div>
    <div class="p-col-6 product">
      <h2 class="product-name">{{ product.name }}</h2>
      <p class="product-desc">
        {{ product.description }}
      </p>
      <div class="product-rating">
        <p-rating [(ngModel)]="product.rating" [cancel]="false" [disabled]="true"></p-rating>
      </div>
      <div class="product-price">
        {{ product.price | currency }}
        <span class="price-before">{{ product.price + 18 | currency }}</span>
      </div>
      <div class="product-quantity">
        <div class="p-field p-col-12 p-md-3 p-p-0">
          <label for="quantity">Quantity</label>
          <p-inputNumber
            [(ngModel)]="quantity"
            mode="decimal"
            [showButtons]="true"
            inputId="quantity"
            [min]="1"
            [max]="100"
          >
          </p-inputNumber>
        </div>
      </div>
      <div class="product-actions">
        <button
          pButton
          pRipple
          type="button"
          label="Buy Now"
          class="p-button-rounded p-mr-2"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          label="Add to Cart"
          class="p-button-rounded"
          (click)="addProductToCart()"
        ></button>
      </div>
    </div>
  </div>
  <div class="p-grid product-rich-desc">
    <div class="p-col-12">
      <div [innerHTML]="product.richDescription"></div>
    </div>
  </div>
</div>

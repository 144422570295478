<div class="banner">
  <div class="p-grid">
    <div class="p-col-5 p-offset-1 banner-text">
      <h1>The Best Products</h1>
      <h2>in Every Category</h2>
      <p-button
        styleClass="p-mt-4 banner-button"
        label="See more"
        icon="pi pi-chevron-right"
        iconPos="right"
      ></p-button>
    </div>
    <div class="p-col-6 banner-image animate__animated animate__slideInLeft">
      <img src="assets/images/banner-image.svg" alt="" />
    </div>
  </div>
</div>

<div class="products-search">
  <i class="pi pi-search"></i>
  <input
    type="text"
    placeholder="Find products"
    class="products-search-input"
    name="search"
    id="search"
  />
</div>

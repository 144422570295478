<div class="order-summary">
  <h3>Order Summary</h3>
  <div class="summary-price">
    <span>Items Price</span>
    <span>{{ totalPrice | currency }}</span>
  </div>
  <div class="summary-price">
    <span>Packing & Shipping</span>
    <span class="free">Free</span>
  </div>
  <div class="to-checkout">
    <div class="summary-price">
      <span>Total Price</span>
      <span>{{ totalPrice | currency }}</span>
    </div>
    <div class="checkout-button" *ngIf="!isCheckout">
      <p-button label="Checkout" (onClick)="navigateToCheckout()"></p-button>
    </div>
  </div>
</div>

<div class="cart-page">
  <div class="p-grid">
    <div class="p-col-8">
      <div>
        <p-button
          label="Continue shopping"
          icon="pi pi-arrow-left"
          (onClick)="backToShop()"
        ></p-button>
      </div>
      <div>
        <h4>My Cart: {{ cartCount }} Item(s)</h4>
      </div>
      <div class="cart-shipping p-mb-5">
        Shipping after the payment, Delivery time : 1 to 4 working days
      </div>
      <div class="cart-item p-mb-5" *ngFor="let cartItem of cartItemsDetailed">
        <div class="p-grid p-fluid">
          <div class="p-col-2 cart-item-image">
            <img [src]="cartItem.product.image" [attr.alt]="cartItem.product.name" />
          </div>
          <div class="p-col-7">
            <div class="cart-item-name">{{ cartItem.product.name }}</div>
            <div class="cart-item-price">{{ cartItem.product.price | currency }}</div>
            <div class="cart-item-remove">
              <p-button icon="pi pi-trash" (onClick)="deleteCartItem(cartItem)"></p-button>
            </div>
          </div>
          <div class="p-col-3">
            <div class="p-field cart-item-quantity">
              <p-inputNumber
                mode="decimal"
                [showButtons]="true"
                [inputId]="cartItem.product.id"
                [min]="1"
                [max]="100"
                [(ngModel)]="cartItem.quantity"
                (onInput)="updateCartItemQuantity($event, cartItem)"
              >
              </p-inputNumber>
            </div>
            <div class="cart-item-subtotal">
              Subtotal:
              <span class="cart-item-subtotal-value">{{
                cartItem.product.price * cartItem.quantity | currency
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-4">
      <orders-order-summary></orders-order-summary>
    </div>
  </div>
</div>

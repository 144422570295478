<div class="products-page">
  <div class="p-grid">
    <div class="p-col-3" *ngIf="!isCategoryPage">
      <h4>Categories</h4>
      <div class="p-field-checkbox" *ngFor="let category of categories">
        <p-checkbox
          [(ngModel)]="category.checked"
          binary="true"
          [inputId]="category.id"
          (onChange)="categoryFilter()"
        ></p-checkbox>
        <label for="{{ category.id }}">{{ category.name }}</label>
      </div>
    </div>
    <div [ngClass]="{ 'p-col-9': !isCategoryPage, 'p-col-12': isCategoryPage }">
      <div class="p-grid" *ngIf="products">
        <div
          [ngClass]="{ 'p-col-4': !isCategoryPage, 'p-col-3': isCategoryPage }"
          *ngFor="let product of products"
        >
          <products-product-item [product]="product"></products-product-item>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="gallery" *ngIf="hasImages">
  <div class="main-image">
    <img [src]="selectedImageUrl" alt="" />
  </div>
  <div class="images">
    <ul>
      <li
        *ngFor="let imageUrl of images"
        (click)="changeSelectedImage(imageUrl)"
        [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }"
      ></li>
    </ul>
  </div>
</div>

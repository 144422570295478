<div class="categories-banner">
  <h3>Categories</h3>
  <div class="p-grid">
    <div class="p-col-2" *ngFor="let category of categories">
      <div
        class="category"
        [routerLink]="'/category/' + category.id"
        [ngStyle]="{ backgroundColor: category.color }"
      >
        <div class="category-icon">
          <i class="pi pi-{{ category.icon }}"></i>
        </div>
        <div class="category-name">{{ category.name }}</div>
      </div>
    </div>
  </div>
</div>

<div class="product-item" *ngIf="product">
  <div class="product-item-wrapper">
    <img [routerLink]="'/products/' + product.id" [src]="product.image" class="image" alt="" />
    <h4 class="name">{{ product.name }}</h4>
    <h5 class="price">{{ product.price | currency }}</h5>
    <p-button
      styleClass="p-mt-3 add-to-cart"
      label="Add to cart"
      icon="pi pi-shopping-cart"
      (onClick)="addProductToCart()"
    ></p-button>
  </div>
</div>
